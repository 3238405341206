@font-face {
  font-family: 'notoSans';
  src: url('./assets/fonts/notosans-regular.woff2') format(woff2),
    url('./assets/fonts/notosans-regular.woff') format(woff);
  font-display: swap;
}

@font-face {
  font-family: 'notoSans';
  src: url('./assets/fonts/notosans-bold.woff2') format(woff2), url('./assets/fonts/notosans-bold.woff') format(woff);
  font-weight: bold;
  font-display: swap;
}

:root {
  --hover: #ff7c00;
  --shadow: #ccc;
  --highlight: #7ec845;
  --dummy-image: linear-gradient(to right, #3385ff, #3385ff);
  --background-color: #ddebff;
  --anchor-color: #ff7c00;
  --anchor-hover: #ff8e1a;
  --anchor-disabled: #ffd9b3;
  --footer-background: #e4e4e4;
  --progress-background: #d4edff;
  --progress-value: #c4ffc4;
  --progress-track: #2c72ff;
  --product-table: #fff9a9;
  --default-color-fallback: white;
  --currency-switch: #004ba3;
  --currency-switch-selected: #00b4f7;
  --saving: #0eb7ae;
  --saving-strikethrough: #cb3b4c;
  --max-width: 60rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'notoSans', sans-serif;
  line-height: 1.35;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  overflow-x: hidden;
  margin-top: 5rem;
}

section {
  margin: 3rem 0 1rem;
}

h2,
h3 {
  padding-top: 0.5rem;
}

h1,
h2,
.newsletter-container__info--text {
  text-align: center;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #0044cc;
}

header a,
footer a {
  color: inherit;
}

a:hover,
a:active,
.newsletter-form__btn button:hover,
.newsletter-form__btn button:active,
.downloadable-container ul li:hover,
.downloadable-container ul li:active,
.downloadable-back:hover,
.downloadable-back:active {
  text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
}

button[type='submit']:disabled {
  text-shadow: unset;
  text-transform: capitalize;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.u-flex-row {
  flex-direction: row !important;
}

.main-navigation {
  display: grid;
  grid-template-columns: 1fr 80% 1fr 1fr;
  align-items: center;
  justify-items: end;
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  max-width: var(--max-width);
}

.mobile {
  display: none;
}

.nav-check,
.lang-check {
  display: none;
}

.items {
  display: flex;
  list-style: none;
}

.items li:first-of-type {
  display: none;
}

.items li:hover,
.items li:active {
  background: var(--hover);
}

.lang-selection,
.logo,
.shopping-cart {
  width: 2.5rem;
}

.logo {
  justify-self: start;
}

.lang-selection,
.shopping-cart {
  justify-self: end;
}

.lang-selection {
  position: relative;
}

.shopping-cart svg,
.lang-selection svg {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.lang-selection img,
.logo img,
.shopping-cart img {
  max-inline-size: 100%;
  block-size: auto;
}

.lang-selection img,
.shopping-cart img {
  cursor: pointer;
}

.language-selection__popup {
  display: none;
  position: absolute;
  top: 3rem;
  right: 2rem;
  z-index: 2;
}

.lang-check:checked ~ .language-selection__popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  box-shadow: 2px 2px 5px var(--shadow);
  padding: 0.5rem;
  background: white;
}

.lang-check:checked ~ .language-selection__popup a {
  width: 8rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.lang-check:checked ~ .language-selection__popup a:first-of-type {
  border-bottom: 1px solid var(--shadow);
}

.anchor {
  color: #000000;
  text-decoration: none;
  width: 8rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#product-overview {
  background-image: url('./assets/images/hero-home/hero-home.jpg');
  background-image: image-set(
    url('./assets/images/hero-home/hero-home.avif') type('image/avif'),
    url('./assets/images/hero-home/hero-home.webp') type('image/webp')
  );
  background-size: cover;
  margin-top: unset;
  display: flex;
  position: relative;
  margin-top: 3rem;
  background-position-y: 50%;
}

#product-overview.product-overview__mentoring {
  background-image: url('./assets/images/hero-training/hero-training.jpg');
  background-image: image-set(
    url('./assets/images/hero-training/hero-training.avif') type('image/avif'),
    url('./assets/images/hero-training/hero-training.webp') type('image/webp')
  );
}

#product-overview.product-overview__training {
  background-image: url('./assets/images/hero-training/hero-training.jpg');
  background-image: image-set(
    url('./assets/images/hero-training/hero-training.avif') type('image/avif'),
    url('./assets/images/hero-training/hero-training.webp') type('image/webp')
  );
}

.product-overview__paragraph--text {
  max-width: 22rem;
}

.product-overview__container--left {
  margin-top: 6rem;
  margin-left: 5rem;
  margin-bottom: 3rem;
}

.product-overview__paragraph--container {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
}

.product-overview__paragraph--container p {
  width: 13rem;
}

.product-overview__container--left > p:last-of-type {
  background-color: rgba(255, 255, 255, 0.6);
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.product-overview__button,
.button__rectangle--right__arrow {
  display: flex;
}

.button__rectangle--right__arrow {
  justify-content: center;
}

.product-overview__container--left .button__rectangle--right__arrow {
  width: 100%;
}

.product-overview__container--left .button__rectangle {
  width: 80%;
  justify-content: space-evenly;
}

.button__rectangle {
  background: var(--anchor-color);
  padding: 0.3rem 1rem;
  width: 100%;
  color: #000000;
}

#product-overview .button__rectangle,
#who-is-behind-it .button__rectangle  {
  padding: 1rem 2rem;
}

.button__rectangle--right__arrow {
  margin: 1rem auto;
  color: inherit;
  width: 21rem;
}

#program-is-for-you .button__rectangle--right__arrow,
#join-cyrinas-academy .button__rectangle--right__arrow {
  width: 27rem;
}

.button__right--arrow {
  width: 0;
  height: 0;
  border-top: 1.7rem solid transparent;
  border-bottom: 1.7rem solid transparent;
  border-left: 2rem solid var(--anchor-color);
  margin-left: 0.5rem;
}

.product-overview__paragraph--container p:first-child {
  font-size: 1.5rem;
  font-weight: bold;
  width: 18rem;
}

.trainings__text a,
#downloadable-content a,
#get-answers a,
.footer__cta,
.product-details__add-to-cart {
  margin: 1rem 0;
  display: block;
  background: var(--anchor-color);
  padding: 1rem;
  color: inherit;
}

.product-overvirew__award--logo {
  position: absolute;
  bottom: 4rem;
  right: 3rem;
}

.product-overvirew__award--logo {
  background: #3349ff;
  width: 8rem;
  height: 8rem;
}

#testemonials {
  display: flex;
  justify-content: space-between;
  margin: 1rem 8rem;
}

.testemonials-logos {
  display: block;
  background: var(--dummy-image);
  border-radius: 50%;
  width: 8rem;
  height: 3rem;
}

.slogan {
  margin: 1rem 0;
}

#description {
  display: flex;
  align-self: center;
}

.description__image {
  width: 30rem;
  height: 18rem;
  background-image: url('./assets/images/cyber-security/cyber-security.jpg');
  background-image: image-set(
    url('./assets/images/cyber-security/cyber-security.avif') type('image/avif'),
    url('./assets/images/cyber-security/cyber-security.webp') type('image/webp')
  );
  background-size: cover;
}

.description__image--mentoring {
  background-image: url('./assets/images/mentoring/mentoring.jpg');
  background-image: image-set(
    url('./assets/images/mentoring/mentoring.avif') type('image/avif'),
    url('./assets/images/mentoring/mentoring.webp') type('image/webp')
  );
  background-position-y: 50%;
}

.description__image--training {
  background-image: url('./assets/images/training/training.jpg');
  background-image: image-set(
    url('./assets/images/training/training.avif') type('image/avif'),
    url('./assets/images/training/training.webp') type('image/webp')
  );
  background-position-y: bottom;
}

.description__image img {
  width: 100%;
}

.description__text {
  display: flex;
  flex-direction: column;
  height: 18rem;
  justify-content: space-around;
  width: 50%;
  padding-left: 1rem;
}

#trainings {
  display: flex;
  margin: 2rem 0;
}

.trainings__cyber--security__training,
.trainings__cyber--security__mentoring {
  width: 50%;
  position: relative;
}

.trainings__cyber--security__training {
  margin-right: 2rem;
}

.trainings__cyber--security__training .trainings__text {
  background-image: url('./assets/images/bg-training/bg-training.jpg');
  background-image: image-set(
    url('./assets/images/bg-training/bg-training.avif') type('image/avif'),
    url('./assets/images/bg-training/bg-training.webp') type('image/webp')
  );
  background-size: cover;
}

.trainings__cyber--security__mentoring {
  margin-left: 2rem;
}

.trainings__cyber--security__mentoring .trainings__text {
  background-image: url('./assets/images/bg-mentoring/bg-mentoring.jpg');
  background-image: image-set(
    url('./assets/images/bg-mentoring/bg-mentoring.avif') type('image/avif'),
    url('./assets/images/bg-mentoring/bg-mentoring.webp') type('image/webp')
  );
  background-size: cover;
}

.trainings__text {
  padding-bottom: 0.5rem;
  height: 27rem;
  position: relative;
}

.trainings__text * {
  color: var(--default-color-fallback);
}

.trainings__text strong {
  color: #000;
}

.trainings__text p {
  padding: 0.3rem 2rem;
}

.trainings__text a {
  position: absolute;
  bottom: 2%;
  left: 5%;
}

.trainings__text a,
#downloadable-content a,
#get-answers a,
.footer__cta,
.product-details__add-to-cart {
  margin: 0 auto;
  padding: 0.3rem 1rem;
  width: 90%;
  text-align: center;
  color: inherit;
}

.trainings__comment {
  display: flex;
  margin: 1rem 0;
}

.trainings__comment--text p:last-of-type {
  position: absolute;
  bottom: 0.5%;
}

.trainings__comment--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trainings__comment--img__container {
  height: 8rem;
  width: 8rem;
  margin-bottom: 0.5rem;
}

.trainings__comment--img__container img {
  max-inline-size: 100%;
  block-size: auto;
  border-radius: 50%;
}

.trainings__comment--img__container p {
  text-align: center;
}

.trainings__comment--text {
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.trainings__comment--rating {
  width: 6.5rem;
  display: flex;
}

.trainings__comment--rating svg use,
.trainings__comment--rating svg path,
.product-details svg use,
.product-details svg path {
  fill: #fff833;
  stroke: #ffcf0d;
  stroke-width: 2rem;
}

#unique-program,
#join-our-mission,
#join-cyrinas-academy {
  padding-bottom: 1rem;
}

#unique-program {
  background-image: url('./assets/images/bg-usp/bg-usp.jpg');
  background-image: image-set(
    url('./assets/images/bg-usp/bg-usp.avif') type('image/avif'),
    url('./assets/images/bg-usp/bg-usp.webp') type('image/webp')
  );
  background-size: cover;
  min-height: 35rem;
  position: relative;
}

#unique-program h2 {
  padding-top: 2rem;
}

#join-our-mission h2 {
  padding-top: 5rem;
}

#unique-program a,
#join-cyrinas-academy a {
  position: absolute;
  bottom: 2%;
  right: 5%;
}

#join-cyrinas-academy p:nth-of-type(1) {
  position: absolute;
  bottom: 30%;
  left: 5%;
}

.unique-program__text {
  padding: 1rem 6rem;
}

#unique-program .unique-program__text {
  padding: 0.5rem 5rem;
  font-weight: 600;
}

.unique-program__text p {
  margin-bottom: 1rem;
}

.central-anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0.3rem 0;
  width: 45%;
  height: 3rem;
  background: var(--anchor-color);
  text-align: center;
  color: inherit;
}

#advantages,
#who-is-behind-it,
#program-is-for-you,
#program-is-not-for-you,
#quiz,
#case-study,
#get-answers,
#requirement,
#faq,
#technical-requirements,
#early-movers,
#what-to-expect {
  margin-left: 2%;
}

.advantages__list {
  padding: 0.5rem 2rem;
  padding-right: 6rem;
}

.advantages__list ul li {
  margin-bottom: 1rem;
  list-style: none;
  position: relative;
}

.advantages__list ul li svg {
  width: 1.5rem;
  position: absolute;
  left: -4%;
}

.advantages__list ul li svg use,
.advantages__list ul li svg path,
.customer-filtering svg .customer-filtering__validity--true {
  fill: #00b549;
}

#program-is-not-for-you .advantages__list ul li svg {
  width: 1rem;
  left: -3%;
}

#program-is-not-for-you .advantages__list ul li svg use,
#program-is-not-for-you .advantages__list ul li svg path,
.customer-filtering svg .customer-filtering__validity--false {
  fill: #ff0000;
}

.program-is-not-for-you__quote {
  width: 20rem;
  float: right;
}

.who-is-behind-it__container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.who-is-behind-it__container .description__text {
  padding-right: 1rem;
}

#join-our-mission,
#join-cyrinas-academy {
  text-align: center;
  padding: 0 6rem;
}

#join-our-mission {
  background-image: url('./assets/images/bg-vision/bg-vision.jpg');
  background-image: image-set(
    url('./assets/images/bg-vision/bg-vision.avif') type('image/avif'),
    url('./assets/images/bg-vision/bg-vision.webp') type('image/webp')
  );
  background-size: cover;
  color: var(--default-color-fallback);
  min-height: 35rem;
}

#program-is-for-you h2 {
  text-align: start;
}

#program-is-for-you p:first-of-type,
#program-is-not-for-you p:first-of-type {
  margin-top: 0.5rem;
}

#program-is-for-you p:last-of-type {
  text-align: center;
  margin-bottom: 1rem;
}

#program-is-not-for-you {
  position: relative;
}

.program-is-not-for-you__humor {
  position: absolute;
  top: 0.7rem;
  left: 18%;
}

.program-is-not-for-you__img--container {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 2%;
  right: 20%;
}

.security-measures {
  width: 14rem;
  height: 13rem;
  border-radius: 50%;
  border: 8px solid black;
  rotate: -6deg;
  background: floralwhite;
  box-shadow: inset 0px 18px 14px 0 rgba(20, 20, 20, 0.8), inset -6px -6px 11px 0 rgba(255, 255, 255, 0.5);
  float: right;
}

.downloadable-content__container {
  display: flex;
  margin-top: 0.5rem;
}

.downloadable-content__container section:first-of-type {
  margin-right: 0.5rem;
}

#downloadable-content h2,
#quiz h2,
#case-study h2,
#get-answers h2,
#faq h2,
#technical-requirements h2,
#requirement h2,
#early-movers h2,
#what-to-expect h2 {
  text-align: start;
}

#downloadable-content h3 {
  text-align: center;
}

.downloadable-modal,
.exit-popup__modal {
  position: fixed;
  top: 30dvh;
  left: 50%;
  width: 40rem;
  background: var(--background-color);
  border-radius: 5px 5px;
  z-index: 102;
  margin-left: -20rem;
}

.exit-popup__modal {
  top: 20dvh;
  z-index: 103;
}

.downloadable-modal .downloadable-form__close,
.exit-popup__modal .downloadable-form__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2.5rem;
  cursor: pointer;
}

.downloadable-modal .downloadable-form__close span,
.exit-popup__modal .downloadable-form__close span,
.main-navigation__mobile--close span {
  background: black;
  width: 2rem;
  display: block;
  height: 0.1rem;
}

.main-navigation__mobile--close {
  width: 2rem;
  height: 2rem;
  display: none;
}

.downloadable-modal .downloadable-form__close span:first-of-type,
.exit-popup__modal .downloadable-form__close span:first-of-type,
.main-navigation__mobile--close span:first-of-type {
  transform: translateY(1.25rem) rotateZ(45deg);
}

.downloadable-modal .downloadable-form__close span:last-of-type,
.exit-popup__modal .downloadable-form__close span:last-of-type,
.main-navigation__mobile--close span:last-of-type {
  transform: translateY(1.15rem) rotateZ(135deg);
}

.downloadable-modal .downloadable-form h1,
.exit-popup__modal .downloadable-form h1 {
  width: 90%;
}

#downloadable-content p {
  padding: 0.5rem 3rem;
  text-align: center;
}

.downloadable-content__img,
.shopping-cart__img {
  background-size: cover;
  color: var(--default-color-fallback);
  width: 100%;
  height: 18rem;
}

.downloadable-content__img {
  background-image: url('./assets/images/cybersecurity-guide/get-your-guide.jpg');
  background-image: image-set(
    url('./assets/images/cybersecurity-guide/get-your-guide.avif') type('image/avif'),
    url('./assets/images/cybersecurity-guide/get-your-guide.webp') type('image/webp')
  );
}

.shopping-cart__img {
  background-image: url('./assets/images/exit-popup/abandoned-shopping-cart.jpg');
  background-image: image-set(
    url('./assets/images/exit-popup/abandoned-shopping-cart.avif') type('image/avif'),
    url('./assets/images/exit-popup/abandoned-shopping-cart.webp') type('image/webp')
  );
}

.downloadable-content__container section:first-of-type div {
  width: 100%;
}

.quiz__img--container {
  display: flex;
  height: 18rem;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.quiz__img {
  background: var(--dummy-image);
  position: relative;
  width: 17rem;
  height: 100%;
  color: inherit;
}

.quiz-result {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.quiz-result__anchor {
  display: block;
  background: var(--anchor-color);
  padding: 1rem;
  text-align: center;
  margin: 1rem;
}

.quiz-result .quiz-result__text {
  position: unset;
  text-transform: capitalize;
}

.quiz__img strong {
  position: absolute;
  bottom: 1%;
  width: 100%;
  margin: auto;
  text-align: center;
}

.quiz__progress--container,
.quiz__progress--value {
  height: 2rem;
  border-radius: 3rem;
}

.quiz__progress--container {
  width: 100%;
  background: var(--progress-background);
  position: relative;
  margin: 0.5rem 0;
}

.quiz__progress--value {
  background: var(--progress-value);
  width: 25%;
}

.quiz__progress--value span {
  position: absolute;
  top: 20%;
  left: 3%;
}

.quiz__progress--track {
  border-radius: 50%;
  background: var(--progress-track);
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: calc(25% - 1rem);
  top: 0;
}

.case-study__container {
  display: flex;
  flex-direction: column;
}

.case-study__container section {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.case-study__text {
  width: 65%;
}

#case-study p,
#case-study a {
  display: block;
  margin-top: 1rem;
}

.case-study__img {
  background: var(--dummy-image);
  width: 17rem;
  height: 18rem;
}

#get-answers a {
  margin: unset;
  width: 40%;
}
#get-answers p {
  margin-top: 0.5rem;
}

.get-answers__links {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-around;
}

footer {
  background: var(--footer-background);
  margin-top: 3rem;
  padding: 1rem;
  padding-bottom: 5rem;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.footer__navigation,
.footer__contact,
.footer__data--protection {
  width: 33%;
}

footer .logo {
  margin-bottom: 1rem;
}

footer ul {
  list-style: none;
}

.footer__nested--list {
  margin-left: 5%;
}

.footer__data--protection ul li:nth-of-type(4),
.footer__contact li:nth-last-of-type(4) {
  margin-top: 2rem;
}

.footer__cta {
  margin: 0.5rem 0;
  width: 80%;
}

.newsletter-container {
  margin-top: 7rem;
}

.newsletter-form__subscription,
.newsletter-form__additional--text {
  text-align: center;
}

.newsletter-form {
  width: 30rem;
  margin: auto;
  margin-bottom: 2rem;
}

.newsletter-form__name,
.newsletter-form__email {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
}

.newsletter-form__phone,
.newsletter-form__select {
  display: flex;
}

.newsletter-form__select {
  margin: 1rem auto;
  justify-content: space-between;
  align-items: center;
}

.newsletter-form__name input,
.newsletter-form__email input,
.newsletter-form__phone select,
.newsletter-form__select select,
.product-detail__currency--change,
.contact-message,
.list-orders__pagination--select {
  border: 1px solid var(--shadow);
  outline: none;
  height: 2rem;
  width: 30rem;
  font-size: 1.5rem;
  border-radius: 5px 5px;
}

.contact-message {
  height: unset;
}

.product-detail__currency--change,
.list-orders__pagination--select {
  width: 10%;
  vertical-align: middle;
  margin-left: 3%;
  height: 1.5rem;
  font-size: 1rem;
}

.product-detail__currency--label {
  vertical-align: middle;
}

.newsletter-form__phone select,
.newsletter-form__select select {
  margin-right: 2rem;
  width: 30%;
  font-size: 1rem;
}

.newsletter-form__select select {
  width: 60%;
  margin-right: unset;
}

.newsletter-form__radio {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
}

.newsletter-form__agree {
  margin: 1rem auto;
  display: flex;
  align-items: center;
}

.newsletter-form__buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.newsletter-form__btn button {
  background: var(--highlight);
  padding: 0.5rem;
  border: 1px solid var(--shadow);
  border-radius: 5px 5px;
  cursor: pointer;
  font: inherit;
  margin-top: 0.5rem;
  text-transform: capitalize;
}

.newsletter-info-form__btn button {
  width: 50%;
}

.newsletter-form__btn--cancel button {
  background: red;
}

.newsletter-title__errors {
  list-style: none;
  color: red;
  width: 30rem;
  margin: auto;
}

.newsletter-form__errors {
  color: red;
}

.downloadable-container,
.downloadable-back {
  display: flex;
  justify-content: center;
}

.downloadable-container ul {
  list-style-type: decimal;
  display: flex;
}

.downloadable-container ul li,
.downloadable-back {
  cursor: pointer;
}

.webinar-table {
  margin: 6rem auto;
}

.webinars__registration-table {
  max-width: 45rem;
  width: 100%;
  margin: auto;
}

.webinars__registration-table,
.webinars__registration-table tr,
.webinars__registration-table th,
.webinars__registration-table td {
  border: 1px solid var(--shadow);
  border-collapse: collapse;
}

.webinars__registration-table a {
  display: flex;
  justify-content: center;
}

.webinars__registration-table td,
.webinars__registration-table th {
  padding: 1rem;
}

.newsletter-from__phone--selection__text {
  margin-top: 1rem;
  text-align: justify;
}

.newsletter-info-form__checkboxes {
  padding-top: 0.2rem;
}

.footer {
  text-align: center;
  margin-top: 10rem;
}

.not-found {
  text-align: center;
  margin-top: 5rem;
}

.webinar-info {
  margin: 1rem 0;
  display: grid;
  justify-content: center;
}

.cookie-banner {
  background: var(--background-color);
  position: fixed;
  bottom: 0rem;
  max-width: var(--max-width);
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  z-index: 999;
}

.cookie-banner--hide {
  display: none;
}

.product-details {
  border-collapse: collapse;
  margin-top: 1rem;
}

.products-title,
.contact-us {
  margin-top: 5rem;
}

.product-list {
  list-style-position: inside;
  margin: 1rem 2rem;
  margin-top: 2rem;
}

.product-details__bestseller {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.product-details tr td:nth-of-type(2),
.product-details tr th:nth-of-type(2) {
  background: var(--product-table);
}

tr.product-details__special--offer td {
  border: 5px solid #36bcff;
}

.product-details tr:first-of-type td:first-of-type,
.product-details tr:first-of-type td:last-of-type {
  border: unset;
}

.product-details tr {
  border: 1px solid var(--shadow);
}

.product-details th {
  font-weight: normal;
}

.product-details td,
.product-details th {
  padding: 0.5rem 1rem;
}

.product-details thead tr:first-of-type {
  border: unset;
}

.product-details__bestseller svg {
  width: 1.5rem;
}

.product-details__add-to-cart {
  padding: 1rem 0;
  margin: 0.5rem auto;
  width: 100%;
}

.product-details__add-to-cart a {
  color: inherit;
}

.product-details__currency,
.product-details__special--offer h3 {
  text-align: center;
}

.product-details__img--container {
  background-size: cover;
  background-position-y: 50%;
  width: 100%;
  height: 15rem;
}

.product-details__basic--img {
  background-image: url('./assets/images/product-mentoring/personal-seat/product-mentoring-personal-seat_800.jpg');
  background-image: image-set(
    url('./assets/images/product-mentoring/personal-seat/product-mentoring-personal-seat_800.webp') type('image/webp'),
    url('./assets/images/product-mentoring/personal-seat/product-mentoring-personal-seat_800.avif') type('image/avif')
  );
  width: 20rem;
}

.product-details__foundation--img {
  background-image: url('./assets/images/product-mentoring/flexible-seat/product-mentoring-flexible-seat_800.jpg');
  background-image: image-set(
    url('./assets/images/product-mentoring/flexible-seat/product-mentoring-flexible-seat_800.webp') type('image/webp'),
    url('./assets/images/product-mentoring/flexible-seat/product-mentoring-flexible-seat_800.avif') type('image/avif')
  );
  width: 20rem;
}

.product-details__champion--img {
  background-image: url('./assets/images/product-mentoring/confidential-group/product-mentoring-confidetial-group_800.jpg');
  background-image: image-set(
    url('./assets/images/product-mentoring/confidential-group/product-mentoring-confidetial-group_800.webp')
      type('image/webp'),
    url('./assets/images/product-mentoring/confidential-group/product-mentoring-confidetial-group_800.avif')
      type('image/avif')
  );
  width: 20rem;
}

.product-details-basic-training__img {
  background-image: url('./assets/images/product-training/training-basic/training-basic_800.jpg');
  background-image: image-set(
    url('./assets/images/product-training/training-basic/training-basic_800.webp') type('image/webp'),
    url('./assets/images/product-training/training-basic/training-basic_800.avif') type('image/avif')
  );
  width: 20rem;
  background-position-x: center;
  background-repeat: no-repeat;
}

.product-details-developer-training__img {
  background-image: url('./assets/images/product-training/training-developer/training-developer_800.jpg');
  background-image: image-set(
    url('./assets/images/product-training/training-developer/training-developer_800.webp') type('image/webp'),
    url('./assets/images/product-training/training-developer/training-developer_800.avif') type('image/avif')
  );
  width: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
}

.product-details-champion-training__img {
  background-image: url('./assets/images/product-training/training-champion/training-champion_800.jpg');
  background-image: image-set(
    url('./assets/images/product-training/training-champion/training-champion_800.webp') type('image/webp'),
    url('./assets/images/product-training/training-champion/training-champion_800.avif') type('image/avif')
  );
  width: 20rem;
  background-position-x: center;
  background-repeat: no-repeat;
}

.product-details__special--offer {
  background: #ddebff;
  border: 5px solid #36bcff;
  padding: 2rem;
  padding-top: 0.5rem;
}

.product-details__special--offer p {
  text-align: center;
  padding: 0.5rem 0;
}

.product-details__special--offer .product-details__add-to-cart {
  width: 50%;
}

.product-details__special--offer ul {
  list-style-type: disclosure-closed;
}

td.product-details__img,
td.product-details__currency--select {
  padding: unset;
}

tr.product-details__image--row {
  border-bottom: unset;
  border-top: unset;
}

.product-details__cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-details__list--anchor {
  margin: unset;
  margin-bottom: 1rem;
}

.product-details__list--text {
  margin-bottom: 1rem;
}

#join-cyrinas-academy {
  background-image: url('./assets/images/bg-join1/bg-join1.jpg');
  background-image: image-set(
    url('./assets/images/bg-join1/bg-join1.avif') type('image/avif'),
    url('./assets/images/bg-join1/bg-join1.webp') type('image/webp')
  );
  background-size: cover;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0;
  color: var(--default-color-fallback);
  min-height: 35rem;
  position: relative;
  background-position-x: center;
}

#technical-requirements p:last-of-type,
#technical-requirements h3 {
  margin-bottom: 1rem;
}

.early-movers__container {
  display: flex;
  justify-content: space-between;
}

.early-movers__item--container {
  width: 31%;
}

.early-movers__item--container__img {
  background-image: url('./assets/images/bg-advantages/bg-advantages_800.jpg');
  background-image: image-set(
    url('./assets/images/bg-advantages/bg-advantages_800.avif') type('image/avif'),
    url('./assets/images/bg-advantages/bg-advantages_800.webp') type('image/webp')
  );
  background-size: cover;
  color: var(--default-color-fallback);
  height: 21rem;
  margin-bottom: 5rem;
}

.early-movers__item--text {
  padding: 1rem;
  color: black;
}

a.product-overview__button--description {
  text-align: center;
  width: 90%;
  height: 3rem;
  padding: 0.3rem 0;
}

.product-description__text {
  justify-content: space-between;
}

.product-derscription__container,
.product-description__reviews {
  display: flex;
}

.product-table__mobile {
  display: none;
}

#what-to-expect > a {
  width: 40%;
}

.product-table__info--text {
  display: inline;
  vertical-align: middle;
  margin-right: 2%;
}

.product-table__info {
  width: 1.3rem;
  vertical-align: middle;
  cursor: pointer;
}

svg.product-table__info path,
svg.product-table__info use {
  fill: #0041ff;
  stroke: unset;
}

.product-table__info--box {
  display: none;
  position: absolute;
  z-index: 3;
  background: var(--shadow);
  width: 50%;
  padding: 0.5rem;
}

.product-details__champion--hidden .product-table__info--box {
  right: 10rem;
}

.product-table__info--box p {
  padding: 0.5rem 0.3rem;
  text-align: justify;
}

.product-table__info:hover ~ .product-table__info--box,
.product-table__info:active ~ .product-table__info--box {
  display: block;
}

.admin-panel__container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-template-rows: 10rem 10rem;
  grid-gap: 1%;
  justify-content: center;
}

.admin-panel__tiles {
  background: var(--dummy-image);
  position: relative;
  text-align: center;
  color: inherit;
}

.admin-panel__text {
  position: absolute;
  bottom: 1%;
  width: 100%;
}

.list-of-orders__table {
  max-width: var(--max-width);
}

.list-of-orders__table tr {
  cursor: pointer;
}

.list-of-orders__table th {
  position: relative;
  width: calc(var(--max-width) / 5);
}

.list-of-orders__table input[type='date'] {
  position: relative;
  z-index: 2;
}

.list-of-orders__table--filters {
  display: flex;
  justify-content: space-evenly;
}

.list-of-orders__table--filters svg {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.order-detail__table tr {
  cursor: default;
}

.status-filtering {
  width: calc(var(--max-width) / 5);
  position: absolute;
  background: white;
  font-weight: normal;
  text-align: left;
  top: 2.5rem;
  left: 0;
}

.date-filtering div {
  padding: 1rem;
}

.date-filtering {
  display: flex;
  justify-content: space-around;
  width: 20rem;
}

.status-filtering__checkbox label {
  padding-left: 0.5rem;
}

.customer-filtering {
  display: flex;
  align-items: center;
}

.customer-filtering input {
  outline: none;
  height: 2rem;
  width: 100%;
  font-size: 1.5rem;
}

.list-orders__pagination,
.numbered-headings__centered--paragraph {
  text-align: center;
  margin-top: 1rem;
}

.list-orders__pagination a:nth-of-type(2) {
  padding: 0 0.3rem;
}

.list-orders__pagination--select {
  width: 2rem;
}

.exit-popup__text {
  margin-top: 3rem;
}

.nowrap {
  white-space: pre;
}

.logout-btn {
  display: flex;
  flex-direction: row-reverse;
}

.order-detail__buttons {
  display: flex;
  justify-content: space-between;
}

.currency-select,
.payment-frequency {
  display: flex;
}

.currency-select a,
.payment-frequency a {
  background: var(--currency-switch);
  color: white;
  padding: 0.5rem;
  width: 34%;
  text-align: center;
  border: 1px solid var(--shadow);
}

.payment-frequency a {
  width: 50%;
}

.currency-select a:hover,
.currency-select a:active,
.currency-select a.active,
.payment-frequency a:hover,
.payment-frequency a:active,
.payment-frequency a.active {
  background: var(--currency-switch-selected);
}

.product-price-wo-discount {
  color: var(--saving-strikethrough);
}

.product-price-w-discount__save {
  color: var(--saving);
}

.numbered-headings {
  counter-reset: h2counter;
}

.numbered-headings__generals,
.numbered-headings h2,
.numbered-headings h3 {
  text-align: start;
}

.numbered-headings__generals p {
  padding: 0.5rem 0;
}

.numbered-headings h2 {
  counter-reset: h3counter;
}

.numbered-headings__list {
  list-style-position: inside;
  padding: 0 1rem;
}

.numbered-headings__list li,
.numbered-headings__dash--list li,
.numbered-headings__letter--list li {
  padding: 0.5rem 0;
}

.numbered-headings__letter--list {
  padding: 0 3rem;
  list-style: none;
  counter-reset: list;
}

.numbered-headings__letter--list li:before {
  content: '(' counter(list, lower-alpha) ') ';
  counter-increment: list;
}

.numbered-headings__dash--list {
  padding: 0 3rem;
  list-style: none;
}

.numbered-headings__dash--list li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
}

.numbered-headings h2:before {
  content: counter(h2counter) '.\0000a0\0000a0';
  counter-increment: h2counter;
}

.numbered-headings h3:before {
  content: counter(h2counter) '.' counter(h3counter) '.\0000a0\0000a0';
  counter-increment: h3counter;
}

.numbered-headings h3.nocount:before {
  content: none;
  counter-increment: none;
}

@media (width <= 60rem) {
  .main-navigation {
    grid-template-columns: 1fr 80% 0% 1fr 1fr;
    padding-top: 0.5rem;
    justify-items: unset;
  }

  .mobile,
  .logo,
  .shopping-cart {
    justify-self: center;
  }

  .items {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 3rem;
    left: 0;
    width: 0dvw;
    height: 0dvh;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .items li {
    border-bottom: 1px solid var(--shadow);
    display: none;
  }

  .anchor {
    justify-content: flex-start;
    margin-left: 3%;
  }

  .mobile {
    display: block;
    background: white;
    border: none;
    position: relative;
    height: 2rem;
    width: 2rem;
  }

  .mobile span {
    border: 2px solid black;
    display: block;
    width: 2rem;
    margin-top: 0.3rem;
    transition: all 0.5s ease;
  }

  .items-container {
    z-index: 1;
  }

  .main-navigation__mobile--close {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }

  .nav-check:checked ~ .items-container .items {
    display: flex;
    justify-content: flex-start;
    background: white;
    width: 100dvw;
    height: 100dvh;
    opacity: 1;
  }

  .nav-check:checked ~ .mobile span:first-of-type {
    transform: translateY(0.55rem) rotateZ(-45deg);
  }
  .nav-check:checked ~ .mobile span:nth-last-of-type(2) {
    display: none;
  }

  .nav-check:checked ~ .mobile span:last-of-type {
    transform: rotateZ(45deg);
    transition: all 0.5s ease;
  }

  .nav-check:checked ~ .items-container .items li {
    display: block;
  }

  .anchor {
    width: 100dvw;
    padding-top: 2.5rem;
  }

  .newsletter-form__btn button {
    width: 100%;
    padding: 0.8rem 0;
  }

  .newsletter-form {
    margin-bottom: 0.5rem;
  }

  .newsletter-form__phone {
    display: unset;
  }

  .newsletter-form__phone select {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: unset;
  }

  .webinars__registration-table {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .webinars__registration-table tr {
    display: grid;
  }

  .webinars__registration-table tr td {
    text-align: center;
  }

  #product-overview {
    display: block;
    background-image: url('./assets/images/hero-home/hero-home_800.jpg');
    background-image: image-set(
      url('./assets/images/hero-home/hero-home_800.webp') type('image/webp'),
      url('./assets/images/hero-home/hero-home_800.avif') type('image/avif')
    );
    height: 25rem;
    background-position-x: right;
  }

  #product-overview.product-overview__training {
    background-image: url('./assets/images/hero-training/hero-training_800.jpg');
    background-image: image-set(
      url('./assets/images/hero-training/hero-training_800.webp') type('image/webp'),
      url('./assets/images/hero-training/hero-training_800.avif') type('image/avif')
    );
  }

  #product-overview.product-overview__mentoring {
    background-image: url('./assets/images/hero-training/hero-training_800.jpg');
    background-image: image-set(
      url('./assets/images/hero-training/hero-training_800.webp') type('image/webp'),
      url('./assets/images/hero-training/hero-training_800.avif') type('image/avif')
    );
  }

  #join-cyrinas-academy {
    background-image: url('./assets/images/bg-join1/bg-join1_800.jpg');
    background-image: image-set(
      url('./assets/images/bg-join1/bg-join1_800.webp') type('image/webp'),
      url('./assets/images/bg-join1/bg-join1_800.avif') type('image/avif')
    );
  }

  .description__image {
    background-image: url('./assets/images/cyber-security/cyber-security_800.jpg');
    background-image: image-set(
      url('./assets/images/cyber-security/cyber-security_800.webp') type('image/webp'),
      url('./assets/images/cyber-security/cyber-security_800.avif') type('image/avif')
    );
  }

  .description__image--training {
    background-image: url('./assets/images/training/training_800.jpg');
    background-image: image-set(
      url('./assets/images/training/training_800.webp') type('image/webp'),
      url('./assets/images/training/training_800.avif') type('image/avif')
    );
  }

  .description__image--mentoring {
    background-image: url('./assets/images/mentoring/mentoring_800.jpg');
    background-image: image-set(
      url('./assets/images/mentoring/mentoring_800.webp') type('image/webp'),
      url('./assets/images/mentoring/mentoring_800.avif') type('image/avif')
    );
  }

  #unique-program {
    background-image: url('./assets/images/bg-usp/bg-usp_800.jpg');
    background-image: image-set(
      url('./assets/images/bg-usp/bg-usp_800.webp') type('image/webp'),
      url('./assets/images/bg-usp/bg-usp_800.avif') type('image/avif')
    );
  }

  #join-our-mission {
    background-image: url('./assets/images/bg-vision/bg-vision_800.jpg');
    background-image: image-set(
      url('./assets/images/bg-vision/bg-vision_800.webp') type('image/webp'),
      url('./assets/images/bg-vision/bg-vision_800.avif') type('image/avif')
    );
  }

  .trainings__cyber--security__training .trainings__text {
    background-image: url('./assets/images/bg-training/bg-training_800.jpg');
    background-image: image-set(
      url('./assets/images/bg-training/bg-training_800.webp') type('image/webp'),
      url('./assets/images/bg-training/bg-training_800.avif') type('image/avif')
    );
  }

  .trainings__cyber--security__mentoring .trainings__text {
    background-image: url('./assets/images/bg-mentoring/bg-mentoring_800.jpg');
    background-image: image-set(
      url('./assets/images/bg-mentoring/bg-mentoring_800.webp') type('image/webp'),
      url('./assets/images/bg-mentoring/bg-mentoring_800.avif') type('image/avif')
    );
  }

  .early-movers__item--container__img {
    margin-bottom: 2rem;
    background-size: 35%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }

  .trainings__comment--text p:last-of-type {
    bottom: -5%;
  }

  .trainings__comment--text__training-page p:last-of-type {
    position: absolute;
    bottom: -12%;
  }

  .product-overview__container--left {
    margin: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  .product-overvirew__award--logo,
  .program-is-not-for-you__img--container,
  .program-is-not-for-you__quote,
  .product-details__basic--hidden,
  .product-details__foundation--hidden,
  .product-details__champion--hidden,
  .product-details--hidden,
  .security-measures {
    display: none;
  }

  .product-overview__paragraph--container {
    padding: 0.2rem;
    text-align: center;
    padding-bottom: unset;
    width: 100%;
  }

  .product-overview__button {
    font-size: 0.9rem;
    width: 100%;
  }

  .product-overview__button a {
    margin: 0.2rem auto;
    width: 85%;
  }

  .product-overview__container--left p {
    text-align: center;
  }

  .product-overview__container--left > p:last-of-type {
    display: none;
  }

  .product-overview__paragraph--container p,
  .product-overview__paragraph--container p:first-child,
  .footer__cta,
  .webinars__registration-table td:first-of-type a,
  .webinars__registration-table td:last-of-type a {
    width: unset;
  }

  .footer__cta {
    padding: 1rem;
  }

  #testemonials {
    margin: 1rem 0;
  }

  .testemonials-logos {
    width: 6rem;
  }

  #description,
  #trainings,
  .who-is-behind-it__container,
  .downloadable-content__container,
  .quiz__img--container,
  .get-answers__links,
  .footer__container,
  .product-derscription__container,
  .product-description__reviews,
  .early-movers__container,
  .newsletter-form__buttons {
    flex-direction: column;
  }

  .description__text {
    display: unset;
    height: unset;
    width: unset;
    padding-left: unset;
    text-align: justify;
    padding: 0.5rem;
  }

  .description__image,
  .trainings__cyber--security__training,
  .trainings__cyber--security__mentoring,
  .downloadable-content__img,
  .shopping-cart__img,
  .downloadable-content__container section:first-of-type div,
  .early-movers__item--container {
    width: 100%;
  }

  .downloadable-content__img,
  .shopping-cart__img {
    background-position-x: center;
  }

  .trainings__cyber--security__training,
  .trainings__cyber--security__mentoring {
    margin-right: unset;
    margin-left: unset;
  }

  .unique-program__text,
  #unique-program .unique-program__text {
    padding: unset;
    text-align: justify;
    padding: 0.5rem;
  }

  .central-anchor,
  #get-answers a {
    width: 90dvw;
  }

  .advantages__list {
    padding: unset;
    padding-left: 1rem;
  }

  #program-is-not-for-you .advantages__list ul li:nth-child(5) {
    padding-right: unset;
  }

  .advantages__list ul li svg {
    width: 1.3rem;
  }

  #who-is-behind-it h3,
  #downloadable-content h2 {
    margin-left: 2%;
  }

  #unique-program p:last-of-type {
    padding-bottom: 3rem;
  }

  .ingo-rauner {
    text-align: center;
  }

  #join-our-mission {
    padding: unset;
  }

  #join-our-mission,
  #unique-program {
    background-position-x: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #unique-program h2,
  #join-our-mission h2 {
    padding-top: 0.5rem;
  }

  .program-is-not-for-you__humor {
    left: unset;
    right: 1%;
    font-size: 0.9rem;
  }

  #downloadable-content section {
    margin-bottom: 1rem;
  }

  .downloadable-content__container section:first-of-type {
    margin-right: unset;
  }

  .downloadable-modal,
  .exit-popup__modal {
    width: 90%;
    left: 5%;
    margin-left: unset;
  }

  #quiz,
  #case-study {
    margin-left: unset;
  }

  #quiz h2,
  .case-study__text h3,
  .case-study__text a,
  #case-study h2 {
    text-align: center;
  }

  .quiz__img--container {
    height: 60rem;
    align-items: center;
  }

  .quiz__img--result {
    height: 18rem;
  }

  div.quiz-result {
    height: 100%;
  }

  .quiz__img {
    height: 30%;
  }

  .case-study__container section {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }

  .case-study__text {
    text-align: justify;
    padding: 0.5rem;
  }

  footer {
    padding-bottom: 9rem;
  }

  footer ul li a {
    display: flex;
    align-items: center;
    height: 3rem;
  }

  .footer__contact {
    margin: 2rem 0;
  }

  .footer__contact li:nth-last-of-type(3) {
    margin-top: unset;
  }

  .footer__data--protection ul li:nth-last-of-type(3) {
    margin-top: unset;
  }

  .footer__navigation,
  .footer__contact,
  .footer__data--protection {
    width: 100%;
  }

  #description-reviews {
    display: flex;
    flex-direction: column;
  }

  #what-to-expect > a {
    width: 90%;
  }

  .early-movers__item--container__img {
    margin-left: -2%;
  }

  .product-detail__currency--change {
    width: 20%;
  }

  .product-details {
    width: unset;
    margin: 0 auto;
  }

  .product-details thead tr:first-of-type {
    border: 1px solid var(--shadow);
  }

  .product-details__basic,
  .product-details__foundation,
  .product-details__champion {
    display: block;
    width: 90dvw;
  }

  .product-table__mobile {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .product-table__mobile a {
    background: var(--anchor-color);
    display: flex;
    width: 30dvw;
    height: 3rem;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--shadow);
    color: #000000;
  }

  .product-table__mobile a:hover,
  .product-table__mobile a:active,
  a.active {
    background: var(--hover);
  }

  .list-of-orders__table thead {
    position: sticky;
    top: 3rem;
    background: var(--background-color);
  }

  .list-of-orders__table,
  .list-of-orders__table tr {
    grid-template-rows: unset;
    grid-template-columns: unset;
  }

  .list-of-orders__table tr {
    border: 1px solid var(--progress-track);
  }

  .list-of-orders__table th {
    width: unset;
  }

  .list-of-orders__table--filters {
    justify-content: space-between;
  }

  .list-of-orders__table--filters > div {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-of-orders__table a {
    justify-content: unset;
    align-items: center;
  }

  .list-of-orders__table--filters svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .list-orders__pagination--select {
    width: 2.5rem;
    height: 2.5rem;
  }

  .status-filtering {
    top: 4rem;
    width: 100%;
    z-index: 3;
    flex-direction: column;
  }

  .status-filtering__checkbox {
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  .date-filtering input {
    margin-left: 1rem;
  }

  .customer-filtering {
    flex-direction: row;
  }

  .admin-panel__container {
    grid-template-rows: unset;
    grid-template-columns: 45% 45%;
    grid-auto-rows: 8rem;
  }

  #join-cyrinas-academy .button__rectangle--right__arrow {
    width: 24rem;
  }

  #join-cyrinas-academy .button__rectangle--right__arrow .button__rectangle {
    padding: 0.3rem;
  }

  .product-details__img--container {
    width: 100%;
  }

  .product-details .product-details__image--row td {
    padding: unset;
  }

  .product-details__img--container {
    height: 25rem;
  }

  .trainings__cyber--security__training .trainings__text,
  .trainings__cyber--security__mentoring .trainings__text {
    background-position-y: 50%;
  }

  .description__image--training {
    background-position-y: 70%;
  }

  .product-table__info--box {
    left: 0;
    width: unset;
  }

  @media (width <= 40rem) {
    .main-navigation {
      grid-template-columns: 1fr 50% 0% 1fr 1fr;
    }

    .newsletter-form,
    .newsletter-title__errors {
      width: 20rem;
    }

    .newsletter-form__name input,
    .newsletter-form__email input,
    .contact-message {
      width: 20rem;
    }

    .product-details__img--container {
      height: 15rem;
    }

    .trainings__text p {
      padding: 0.3rem;
    }

    .early-movers__item--container__img {
      background-size: 60%;
    }
  }

  @media (width <= 33rem) {
    .advantages__list ul li svg,
    #program-is-not-for-you .advantages__list ul li svg {
      left: -6%;
    }
  }
}

