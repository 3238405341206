
.button-arrow {
  width: 0;
  height: 0;
  border-top: 1.2rem solid transparent;
  border-bottom: 1.2rem solid transparent;
  border-left: 1.8rem solid var(--anchor-color);
  margin-left: 0.5rem;
  }

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper .button {
  display: flex;
  padding: 1rem;
  min-width: 40%;
}
.button-wrapper .button-text {
  background-color: var(--anchor-color);
  border-radius: 4px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.3rem;
  min-width: 11rem;
  text-transform: none;
}

.button-wrapper .button:hover {
  background-color: white;
}

.button-wrapper .button:hover .button-text {
  background-color: var(--anchor-hover);
}
.button-wrapper .button:hover .button-text .text {
  font-weight: bold;
}

.button-wrapper .button:hover .button-arrow {
  border-left: 1.8rem solid var(--anchor-hover);
}

.button-wrapper .button:disabled .button-arrow {
  border-left: 1.8rem solid var(--anchor-disabled);
}

.button-wrapper .button:disabled .button-text {
  background-color: var(--anchor-disabled);
}
