.main-container {
  margin-bottom: 4rem;
}

.paper {
  margin: 0;
  padding: 2rem;

  @media (min-width: 600px) {
    margin: 0;
    padding: 3rem;
  }
}

.heading {
  text-align: center;
}

.stepper {
  padding-top: 1rem;
  padding-bottom: 2rem;

  @media (min-width: 600px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.checkbox-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1rem;
  flex-direction: column;
  margin-bottom: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.checkbox-container .text {
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.checkbox-container .legal-text {
  @media (max-width: 600px) {
    font-size: 13px;
    text-align: center;
  }
}

.captcha-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.captcha-hidden {
  display: none;
}

.checkbox-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.agreement {
  font-weight: bold;
  color: #2196f3;
}

.button {
  margin-left: 1rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  height: 36px;

  @media (max-width: 600px) {
    justify-content: center;
  }
}

.error-message {
  color: #d32f2f
}

.contact-phone-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;  
}

.order-status-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  align-self: center;
  justify-self: center;
  margin: 1rem 0rem;
  text-align: center;
}

.order-tracing-info-wrapper {
  margin: 2.5rem 0rem;
}