.icon-container {
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.shopping-cart-drawer {
    width: 450px;

    @media (max-width: 480px) {
      width: 100%;
    }
}

.empty-cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.loader {
    padding: 0;
    margin-bottom: 20px;
}
  
.paper {
    padding: 5px;
}
  
.border-bottom {
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
}

.item-removed {
    opacity: 0.5;
    background-color: lightgray !important;
    margin-bottom: 10px !important;
}
  
.box {
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
}
  
.total {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
  
.bold {
    font-weight: bold;
}
  
.center {
    text-align: center;
}

.right-padding {
    padding-right: 6px;
}

.close-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
    padding-right: 5px;
}

.save-shopping-cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center
}

.save-shopping-cart-wrapper .save-shopping-cart-button {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 40%;
    align-self: flex-end;
    justify-self: flex-start;
    text-transform: none;
}

.itemsListScrollable {
    max-height: 450px;
    overflow-y: auto;
    padding: 5px;
    margin-bottom: 10px;
  }